import * as React from 'react'
import { useModalContext } from '../../../contexts/modal-context'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import { Button, ButtonOwnProps } from '../../atoms/Button'

type ModalButtonProps = {
  modalId: string
  modalProps?: Record<string, unknown>
} & PropsWithRequiredChildren<
  ButtonOwnProps & React.ComponentPropsWithoutRef<'button'>
>

export const ModalButton: React.VFC<ModalButtonProps> = (props) => {
  const {
    modalId,
    children,
    onClick,
    modalProps,
    type = 'button',
    ...restProps
  } = props
  const { openModal } = useModalContext()

  return (
    <Button
      {...restProps}
      as="button"
      type={type}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(e)
        openModal(modalId, modalProps)
      }}
    >
      {children}
    </Button>
  )
}
